exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-my-bill-js": () => import("./../../../src/pages/pay-my-bill.js" /* webpackChunkName: "component---src-pages-pay-my-bill-js" */),
  "component---src-pages-templates-article-js": () => import("./../../../src/pages/templates/Article.js" /* webpackChunkName: "component---src-pages-templates-article-js" */),
  "component---src-pages-templates-basic-js": () => import("./../../../src/pages/templates/Basic.js" /* webpackChunkName: "component---src-pages-templates-basic-js" */),
  "component---src-pages-templates-service-location-js": () => import("./../../../src/pages/templates/ServiceLocation.js" /* webpackChunkName: "component---src-pages-templates-service-location-js" */),
  "component---src-pages-templates-service-provider-js": () => import("./../../../src/pages/templates/ServiceProvider.js" /* webpackChunkName: "component---src-pages-templates-service-provider-js" */)
}

